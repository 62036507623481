<script setup lang="ts">
import type { IconClass } from '#core/types'
import type { ClassNameValue } from 'tailwind-merge'
import { hasListener } from '#core/utils/vue'
import { twMerge } from 'tailwind-merge'
import UiIcon from '../element/UiIcon.vue'

export type BadgeColor = keyof typeof colors

export interface UiBadgeProps {
  // sets the main label text
  label?: string
  // sets the size
  size?: keyof typeof sizes
  // sets the color
  color?: BadgeColor
  // sets an additional status icon, and with it color and layout
  status?: keyof typeof statusColors
  // sets the icon
  icon?: IconClass
  // positions the icon after the text
  trailing?: boolean
  // sets and shows an additional line of text
  info?: string
  // sets a tooltip on hover
  tooltip?: string
  // shows a trailing help icon
  help?: boolean
  // disables the component
  disabled?: boolean
}

// disable attr inheriting
defineOptions({
  inheritAttrs: false,
})

/**
 * UiBadge
 *
 * Replaces Badge, Status and Chip components
 *
 * @see https://github.com/forged-com/forgd/pull/1277
 */
const props = withDefaults(
  defineProps<UiBadgeProps>(),
  {
    size: 'sm',
    color: 'none',
  },
)

const emit = defineEmits(['close'])

const colors = {
  none: `     text-primary-300 border-neutral-600/60 bg-neutral-600/30`,
  primary: `  text-primary-300 border-primary-300/30 bg-primary-300/10`,
  neutral: `  text-neutral-700 border-neutral-600/60 bg-neutral-600/10`,
  green: `    text-green-600 border-green-600/30 bg-green-600/10`,
  yellow: `   text-yellow-700 border-yellow-700/30 bg-yellow-700/10`,
  red: `      text-red-600 border-red-600/30 bg-red-600/10`,
  gray: `     text-slate-600 border-slate-600/30 bg-slate-600/10`,
}

const statusColors: Record<string, keyof typeof colors> = {
  default: 'gray',
  info: 'primary',
  success: 'green',
  warning: 'yellow',
  error: 'red',
}

const statusIcons: Record<string, IconClass> = {
  default: `i-heroicons-information-circle`,
  info: `i-heroicons-information-circle`,
  success: `i-heroicons-check-circle`,
  warning: `i-heroicons-exclamation-triangle`,
  error: `i-heroicons-exclamation-circle`,
}

const sizes = {
  sm: 'px-2 py-1 gap-1 text-xs',
  md: 'px-3 py-2 gap-2 text-[0.8125rem] leading-tight',
  lg: 'px-4 py-3 gap-3 text-sm',
}

function getPadding(size: keyof typeof sizes, icon: string | undefined, trailing: boolean): string {
  const offsets = {
    sm: 2,
    md: 3,
    lg: 4,
  }
  const offset = offsets[size]
  return icon
    ? trailing
      ? `pl-${offset}`
      : `pr-${offset}`
    : `px-${offset}`
}

const attrs = useAttrs()

const icon = computed(() => props.icon
  ? props.icon
  : props.status
    ? statusIcons[props.status]
    : undefined)

const color = computed(() => props.status
  ? statusColors[props.status]
  : props.color)

const classMain = computed(() => {
  return twMerge(
    colors[color.value] || 'neutral',
    sizes[props.size],
    getPadding(props.size, icon.value, props.trailing),
    attrs.class as ClassNameValue[],
    props.info
      ? 'pl-3 py-2 pr-4 gap-2'
      : 'gap-1',
    props.disabled
      ? 'text-neutral-700 bg-neutral-600/30 border-neutral-600/60'
      : '',
  )
})

const classLabel = computed(() => {
  return props.info
    ? [
        'font-normal text-sm',
        props.disabled
          ? 'text-neutral-700'
          : 'text-slate-600',
      ]
    : ''
})

const classInfo = computed(() => {
  return props.disabled
    ? 'text-neutral-700'
    : 'text-primary-900'
})

const classIcon = computed(() => {
  return [
    'shrink-0',
    props.info ? 'size-6' : 'size-4',
    props.trailing ? 'order-1' : '',
  ]
})
// need to use a tooltip component vs css as the tooltip may need to dynamically change position
const rootAs = props.tooltip ? resolveComponent('UiTooltip') : 'span'
const rootAttrs = props.tooltip ? { text: props.tooltip } : {}
</script>

<template>
  <component :is="rootAs" v-bind="rootAttrs">
    <span
      v-bind="$attrs"
      data-ui="UiBadge"
      :class="classMain"
      class=" inline-flex items-center
            border rounded-full
            font-normal
            cursor-default
            "
    >
      <!-- icon -->
      <UIcon v-if="icon" :name="icon" :class="classIcon" />

      <!-- text -->
      <span class="flex flex-col leading-none text-nowrap">
        <span class="flex items-center gap-1 font-mono leading-none tracking-tighter" :class="classLabel">
          <slot>{{ label }}</slot>
        </span>
        <span v-if="info" class="flex font-medium leading-none mt-1" :class="classInfo">
          <slot name="info">{{ info }}</slot>
        </span>
      </span>

      <!-- help icon -->
      <UiIcon v-if="help" name="i-heroicons-question-mark-circle" />

      <!-- extra -->
      <slot name="extra">
        <UiIcon
          v-if="hasListener('close')"
          name="i-heroicons-x-mark"
          class=" cursor-pointer hover:black"
          @click="() => emit('close')"
        />
      </slot>
    </span>
  </component>
</template>
